<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-start self-center w-11/12 mt-8 bg-yellow p-4">
      <h2 class="mb-4 text-2xl font-bold pl-6">P02</h2>
      <v-data-table
        :headers="headers"
        :items="challengesP02"
        :items-per-page="10"
        class="w-full"
        :loading="loadingData"
        loading-text="Cargando retos"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ new Date(item.createdAt).toLocaleDateString() }}
        </template>

        <template v-slot:[`item.photosFormatted`]="{ item }">
          <div class="flex flex-col items-start">
            <a
              :href="img.url"
              target="_blank"
              v-for="(img, idx) in item.photosFormatted"
              :key="idx"
            >Ver Foto {{ img.invoice }}</a>
          </div>
        </template>
        
        <template v-slot:[`item.invoicesFormatted`]="{ item }">
          <div class="flex flex-col items-start">
            <a
              :href="img.url"
              target="_blank"
              v-for="(img, idx) in item.invoicesFormatted"
              :key="idx"
            >Ver Factura {{ img.invoice }}</a>
          </div>
        </template>

        <template v-slot:[`item.accept`]="{ item }">
          <button
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="setChallengePoint(item, 1)"
          >Aceptar</button>
        </template>



        <template v-slot:[`item.reject`]="{ item }">
          <button
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="rejectChallengePoint(item, 1)"
          >Rechazar</button>
        </template>
      </v-data-table>
    </div>
    <Loader :isLoading="loadingData" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      challengesP02: [],
      headers: [
        {
          text: "Id del reto",
          align: "start",
          value: "challengeId",
        },
        { text: "Fecha", value: "createdAt" },
        { text: "Fotos", value: "photosFormatted" },
        { text: "Facturas", value: "invoicesFormatted" },
        { text: "Usuario", value: "uid" },
        { text: "Aceptar", value: "accept" },
        { text: "Rechazar", value: "reject" },
      ],
      loadingData: true,
      currentPeriod: null
    };
  },
  async mounted() {
    const res = await this["challenges/getDCMChallenges"]();
    if (res.success) {
      this.challengesP02 = res.challengesP02;
    }

    const res6 = await this["user/getCurrentPeriod"]();
    if (res6.success) {
      this.currentPeriod = res6.currentPeriod;
    }
    this.loadingData = false;
    this.isLoading = false;
  },

  methods: {
    ...mapActions([
      "challenges/getDCMChallenges",
      "challenges/setDCMChallengePoint",
      "challenges/rejectDCMChallengePoint",
      "user/getCurrentPeriod",
    ]),

    async setChallengePoint(challenge, id){
      this.loadingData = true;
      const res = await this["challenges/setDCMChallengePoint"](challenge);
      if(res.success) {
        if(id === 1) {
          const index = this.challengesP02.indexOf(challenge);
          this.challengesP02.splice(index, 1);
        }
      }
      this.loadingData = false;
    },
    async rejectChallengePoint(challenge, id) {
      this.loadingData = true;
      const res = await this["challenges/rejectDCMChallengePoint"](challenge.id);

      if(res.success) {
        if(id === 1) {
          const index = this.challengesP02.indexOf(challenge);
          this.challengesP02.splice(index, 1);
        }
      }
      this.loadingData = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>